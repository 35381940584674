exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-boats-js": () => import("./../../../src/pages/services/boats.js" /* webpackChunkName: "component---src-pages-services-boats-js" */),
  "component---src-pages-services-carpentry-js": () => import("./../../../src/pages/services/carpentry.js" /* webpackChunkName: "component---src-pages-services-carpentry-js" */),
  "component---src-pages-services-cleaning-js": () => import("./../../../src/pages/services/cleaning.js" /* webpackChunkName: "component---src-pages-services-cleaning-js" */),
  "component---src-pages-services-consulting-js": () => import("./../../../src/pages/services/consulting.js" /* webpackChunkName: "component---src-pages-services-consulting-js" */),
  "component---src-pages-services-electricity-js": () => import("./../../../src/pages/services/electricity.js" /* webpackChunkName: "component---src-pages-services-electricity-js" */),
  "component---src-pages-services-furniture-js": () => import("./../../../src/pages/services/furniture.js" /* webpackChunkName: "component---src-pages-services-furniture-js" */),
  "component---src-pages-services-gas-js": () => import("./../../../src/pages/services/gas.js" /* webpackChunkName: "component---src-pages-services-gas-js" */),
  "component---src-pages-services-green-roofs-js": () => import("./../../../src/pages/services/greenRoofs.js" /* webpackChunkName: "component---src-pages-services-green-roofs-js" */),
  "component---src-pages-services-heating-js": () => import("./../../../src/pages/services/heating.js" /* webpackChunkName: "component---src-pages-services-heating-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-plumbing-js": () => import("./../../../src/pages/services/plumbing.js" /* webpackChunkName: "component---src-pages-services-plumbing-js" */),
  "component---src-pages-services-renovation-js": () => import("./../../../src/pages/services/renovation.js" /* webpackChunkName: "component---src-pages-services-renovation-js" */),
  "component---src-pages-services-roofs-js": () => import("./../../../src/pages/services/roofs.js" /* webpackChunkName: "component---src-pages-services-roofs-js" */)
}

